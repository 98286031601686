import { FormGroup, FormControl, Validators } from '@angular/forms';

export class CustomValidators {
  public static passwordsMatch(group: FormGroup): { [s: string]: boolean } {
    if (
      group.controls['password-1'].value !== group.controls['password-2'].value
    ) {
      return { passwordStrength: false };
    }
    return null;
  }

  public static passwordStrength(control: FormControl): {
    [s: string]: boolean;
  } {
    const regex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
    );

    if (control.value && !regex.test(control.value)) {
      return { passwordStrength: false };
    }

    return null;
  }
}
